<template>
  <!-- begin::DetailsPanel -->
  <div class="d-flex details-panel">
    <span class="svg-icon svg-icon-3x svg-icon-primary" v-if="svgIconSrc !== '' && svgIconSrc !== null">
      <inline-svg :src="svgIconSrc"></inline-svg>
    </span>
    <i v-if="iconSrc !== '' && iconSrc !== null" class="text-primary icon-xxl mr-2" :class="iconSrc"></i>
    <div class="details ml-2 w-100">
      <!-- Title -->
      <b-skeleton-wrapper v-if="title != undefined && title != ''" :loading="!title">
        <template #loading>
          <b-skeleton animation="fade" :width="Math.floor(50 + Math.random() * 50) + '%'"></b-skeleton>
        </template>
        <span
          v-if="title != ''"
          class="d-block"
          :class="
            inverted ? 'font-size-h3 font-weight-bold' : (mobile ? 'font-size-xs text-primary font-weight-bold ' : 'font-size-sm') + ' font-weight-normal'
          "
          v-b-popover="{ title: $gettext(title), content: $gettext(titleDescription), trigger: 'hover', html: true, disabled: !popovers }"
        >
          <!--:title="$gettext(title)" v-b-popover.hover.html="$gettext(titleDescription)"-->
          <inline-svg v-if="inlineSvgIconSrc !== '' && inlineSvgIconSrc !== null" :src="inlineSvgIconSrc" class="ml-n1" />
          <i v-if="inlineIconSrc !== '' && inlineIconSrc !== null && inverted" class="text-opalean-gray-medium icon-md" :class="inlineIconSrc"></i>
          {{ $gettext(title) }} <span v-if="titleMuted" class="text-muted">{{ $gettext(titleMuted) }}</span>
        </span>
      </b-skeleton-wrapper>
      <!-- Subtitle -->
      <b-skeleton-wrapper v-if="subtitle != undefined && subtitle != ''" :loading="!subtitle">
        <template #loading>
          <b-skeleton animation="fade" :width="Math.floor(50 + Math.random() * 50) + '%'"></b-skeleton>
        </template>
        <span
          v-if="subtitle != ''"
          class="d-block"
          :class="inverted ? 'font-size-xs font-weight-bold text-opalean-gray-medium' : (mobile ? 'font-size-lg' : 'font-size-h5') + ' font-weight-bolder'"
          v-b-popover="{ title: $gettext(subtitle), content: $gettext(subtitleDescription), trigger: 'hover', html: true, disabled: !popovers }"
        >
          <i v-if="inlineIconSrc !== '' && inlineIconSrc !== null && !inverted" class="text-opalean-gray-medium icon-md" :class="inlineIconSrc"></i>
          {{ $gettext(subtitle) }}
        </span>
      </b-skeleton-wrapper>
      <!--:title="$gettext(subtitle)"  v-b-popover.hover.html="$gettext(subtitleDescription)"-->
      <!-- Thirdtitle -->
      <b-skeleton-wrapper v-if="thirdtitle != undefined && thirdtitle != ''" :loading="!thirdtitle">
        <template #loading>
          <b-skeleton animation="fade" :width="Math.floor(50 + Math.random() * 50) + '%'"></b-skeleton>
        </template>
        <span
          v-if="thirdtitle != ''"
          class="d-block"
          :class="inverted ? 'font-size-xs font-weight-bold text-opalean-gray-medium' : (mobile ? 'font-size-md' : 'font-size-h6') + ' font-weight-medium'"
          v-b-popover="{ title: $gettext(thirdtitle), content: $gettext(thirdtitleDescription), trigger: 'hover', html: true, disabled: !popovers }"
        >
          {{ $gettext(thirdtitle) }}
        </span>
      </b-skeleton-wrapper>
      <!--:title="$gettext(subtitle)"  v-b-popover.hover.html="$gettext(subtitleDescription)"-->
    </div>
  </div>
  <!-- end::DetailsPanel -->
</template>

<script>
export default {
  name: "DetailsPanel",
  props: {
    iconSrc: {
      type: String,
      default: null,
    },
    inlineIconSrc: {
      type: String,
      default: null,
    },
    svgIconSrc: {
      type: String,
      default: null,
    },
    inlineSvgIconSrc: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    titleMuted: String,
    titleDescription: String,
    subtitle: String,
    subtitleDescription: String,
    thirdtitle: String,
    thirdtitleDescription: String,
    inverted: Boolean,
    mobile: {
      type: Boolean,
      default: false,
    },
    popovers: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
